import { User } from 'features/users/types/user'

import { UserData } from 'features/users/services/types'

import useForm from 'services/hooks/use_form'
import useFilter, { IFilter } from 'services/hooks/use_filter'
import useCategory from 'services/hooks/use_category'
import { isPresent } from 'services/helpers/values'
import { validateEmail } from 'services/helpers/form_validation'

export interface UserFilters {
  firstNameFilter: IFilter<'text'>
  lastNameFilter: IFilter<'text'>
  emailFilter: IFilter<'text'>
  organizationFilter: IFilter<'select'>
  companyFilter: IFilter<'select'>
  mainRolesFilter: IFilter<'select'>
  optionalRolesFilter: IFilter<'multiselect'>
  organizationCodesFilter: IFilter<'tags'>
}

const useUserForm = (user?: User) => {
  const firstNameFilter = useFilter({
    name: 'firstName',
    type: 'text',
    required: true,
    defaultValue: user?.firstName,
    disabled: false,
  })

  const lastNameFilter = useFilter({
    name: 'lastName',
    type: 'text',
    required: true,
    defaultValue: user?.lastName,
    disabled: false,
  })

  const emailFilter = useFilter({
    name: 'email',
    type: 'text',
    required: true,
    defaultValue: user?.email,
    disabled: false,
    customValidation: validateEmail,
  })

  const organizationFilter = useFilter({
    name: 'organization',
    type: 'select',
    required: true,
    defaultValue: user?.organizationId
      ? {
          value: user.organizationId,
          label: user.organizationName,
        }
      : null,
    disabled: isPresent(user),
  })

  const companyFilter = useFilter({
    name: 'company',
    type: 'select',
    required: false,
    defaultValue: user?.companyId
      ? {
          value: user.companyId,
          label: user.companyName ?? '',
        }
      : null,
    disabled: false,
  })

  const mainRolesFilter = useFilter({
    name: 'mainRoles',
    type: 'select',
    required: true,
    defaultValue: user?.mainRole ? { value: user.mainRole.id, label: user.mainRole.role } : null,
    disabled: false,
  })

  const optionalRolesFilter = useFilter({
    name: 'optionalRoles',
    type: 'multiselect',
    required: false,
    defaultValue: user?.optionalRoles
      ? user.optionalRoles.map((r) => ({ value: r.id, label: r.role }))
      : null,
    disabled: false,
  })

  const organizationCodesFilter = useFilter({
    name: 'organizationCodes',
    type: 'tags',
    required: false,
    defaultValue: user?.organizationCodes,
    disabled: false,
  })

  const filters: UserFilters = {
    firstNameFilter,
    lastNameFilter,
    emailFilter,
    organizationFilter,
    companyFilter,
    mainRolesFilter,
    optionalRolesFilter,
    organizationCodesFilter,
  }

  const category = useCategory({
    name: 'userDetails',
    filters: Object.values(filters),
  })

  const { isValid, isEdited, reset } = useForm({ categories: [category] })

  const userPayload: UserData = {
    id: user?.id,
    firstName: filters.firstNameFilter.value?.toString() ?? '',
    lastName: filters.lastNameFilter.value?.toString() ?? '',
    email: filters.emailFilter.value?.toString() ?? '',
    organizationId: (filters.organizationFilter.value?.value as number) ?? null,
    companyId: (filters.companyFilter.value?.value as number) ?? null,
    roleIds: [
      filters.mainRolesFilter.value?.value as number,
      ...(filters.optionalRolesFilter.value?.map((r) => r.value as number) ?? []),
    ],
    organizationCodes: (filters.organizationCodesFilter.value as string[]) ?? [],
  }

  return {
    isValid,
    isEdited,
    reset,
    filters,
    userPayload,
  }
}

export default useUserForm
