import React, { FC } from 'react'

import { StyledViewSwitcher, StyledText, StyledSwitchIcon } from 'components/view_switcher/style'

export interface ViewSwitcherProps {
  label: string
  onSwitch: () => void
}

const ViewSwitcher: FC<ViewSwitcherProps> = ({ label, onSwitch }) => (
  <StyledViewSwitcher onClick={onSwitch}>
    <StyledText>{label}</StyledText>
    <StyledSwitchIcon />
  </StyledViewSwitcher>
)

export default ViewSwitcher
