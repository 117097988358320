import React, {
  FC,
  NamedExoticComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
} from 'react'

import Icon from 'components/icon'

import { StyledModalHeader, StyledModalHead, StyledModalClose } from 'components/modal/style'

import InternalModalContext from 'components/modal/internal_context'

import useModal from 'components/modal/hooks/use_modal'

interface HeaderProps {
  testId?: string
}

const HeaderComponent: FC<HeaderProps> = ({ testId, children }) => {
  const { name } = useContext(InternalModalContext)
  const { setOpen } = useModal(name)
  const onClick = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <StyledModalHeader>
      {children && <StyledModalHead data-testid={testId}>{children}</StyledModalHead>}
      <StyledModalClose $dark={!children} onClick={onClick}>
        <Icon name='close' testId='modal-close-button' />
      </StyledModalClose>
    </StyledModalHeader>
  )
}

const Header = memo(HeaderComponent) as NamedExoticComponent<PropsWithChildren<HeaderProps>>

export default Header
