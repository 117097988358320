import React, { MouseEvent } from 'react'

import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { useSelector } from 'react-redux'

import iconsMapping from 'services/helpers/icons_mapping'
import DateHelper from 'services/helpers/date_helper'
import useBreakpoints from 'services/hooks/use_breakpoints'

import S from 'views/trusted_routes/components/item/style'

import Button from 'components/button'
import Icon from 'components/icon'
import SimpleTransportPlan from 'components/simple_transport_plan'
import Co2 from 'components/co2'
import Logo, { ScacLogoName } from 'components/logo'
import AccordionItem from 'components/accordion_item'
import HoverTooltip from 'components/hover_tooltip'
import IconTooltip from 'components/icon_tooltip'
import Tabs, { Tab } from 'components/tabs'

import {
  RATE_20_DRY,
  RATE_40_DRY,
  RATE_40_HC,
  RateValue,
  selectActiveQueryParams,
  selectDisplayRateType,
} from 'views/trusted_routes/slice'

import { compactArray } from 'services/helpers/values'

import TransportDetails from 'views/trusted_routes/components/item/transport_details'
import Rates from 'views/trusted_routes/components/item/rates'
import { formatRateType } from 'views/trusted_routes/components/item/tool'
import TrustedRouteMap from 'views/trusted_routes/components/item/trusted_route_map'
import useBookingRole from 'views/booking/hooks/use_booking_role'

import {
  getTestIdForTrustedRouteItem,
  getTestIdForTrustedRouteTabTransportDetails,
  getTestIdForTrustedRouteTabRates,
} from 'tests/e2e/test_ids'

import type { Notation, Rate, TrustedRoute } from 'views/trusted_routes/types/trusted_route'

const EmptyCriteria: React.FC = () => <div>-</div>

interface ItemProps {
  trustedRoute: TrustedRoute
  convertToBooking: (trustedRoute: TrustedRoute) => void
  index: number
}

const selectRate = (
  trustedRoute: TrustedRoute,
  displayRate: RateValue
): [Rate | null, number | null] => {
  switch (String(displayRate)) {
    case RATE_20_DRY:
      return [trustedRoute.bestRate20Dry, trustedRoute.bestRate20Dry?.rate20Dry || null]
    case RATE_40_DRY:
      return [trustedRoute.bestRate40Dry, trustedRoute.bestRate40Dry?.rate40Dry || null]
    case RATE_40_HC:
      return [trustedRoute.bestRate40HC, trustedRoute.bestRate40HC?.rate40Hc || null]
    default:
      return [null, null]
  }
}

const Item: React.FC<ItemProps> = ({ trustedRoute, convertToBooking, index }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const { isShipper } = useBookingRole()
  const activeQueryParams = useSelector(selectActiveQueryParams)
  const { isDesktopOrUpper } = useBreakpoints()
  const displayRateType = useSelector(selectDisplayRateType)
  const currentLanguage = i18n.language

  const colorFromNotation = (notation: Notation): string => {
    switch (notation) {
      case 'A':
        return '#41be64'
      case 'B':
        return theme.success
      case 'C':
        return theme.tip
      case 'D':
        return theme.warn
      case 'E':
        return theme.error
      default:
        return theme.error
    }
  }
  const [rate, value] = selectRate(trustedRoute, displayRateType)
  const alerts = compactArray(trustedRoute.legs.map((leg) => leg.alerts)).flat()

  return (
    <S.Container>
      <AccordionItem
        testId={getTestIdForTrustedRouteItem(index)}
        heading={
          <S.Card>
            <S.CardLeftContainer>
              <Logo name={`scacs/${trustedRoute.scac as ScacLogoName}`} width={100} height={70} />
              <S.TransportContent>
                <SimpleTransportPlan
                  locationFrom={trustedRoute.legs[0].departure.port.name}
                  locationTo={trustedRoute.legs.slice(-1)[0].arrival.port.name}
                />
                <S.Text $faded>
                  {`${new DateHelper(
                    trustedRoute.legs[0].departure.date
                  ).formattedOriginalDate()} ${t('operators.to')} ${new DateHelper(
                    trustedRoute.legs.slice(-1)[0].arrival.date
                  ).formattedOriginalDate()}`}
                </S.Text>
              </S.TransportContent>
              {isDesktopOrUpper && (
                <S.TransportContent>
                  <S.Text $faded>
                    {t('trustedRoutes.routing.transshipment', {
                      count: trustedRoute.legs.length - 1,
                    })}
                  </S.Text>
                  {trustedRoute.legs.length > 1 && (
                    <S.Text $tabbed $faded>
                      {`via ${trustedRoute.legs
                        .slice(0, -1)
                        .map((leg) => leg.arrival.port.name)
                        .join('/')}`}
                    </S.Text>
                  )}
                  {trustedRoute.legs[0].vesselName && (
                    <S.Vessel>
                      <S.ShipmentIcon
                        as={Icon}
                        name={iconsMapping('sea', 'transportation')}
                        width={24}
                        height={24}
                      />
                      <S.VesselName $faded>{trustedRoute.legs[0].vesselName}</S.VesselName>
                    </S.Vessel>
                  )}
                </S.TransportContent>
              )}
            </S.CardLeftContainer>
            <S.VerticalBar />
            <S.Criteria>
              <S.Criterion $type='days'>
                <S.CriterionContent>{trustedRoute.transitTime}</S.CriterionContent>
                <S.Text>{t('trustedRoutes.criteria.day')}</S.Text>
              </S.Criterion>

              <S.Criterion $type='reliability'>
                <S.CriterionContent>
                  {trustedRoute.reliability?.notation ? (
                    <HoverTooltip
                      delayed
                      size='large'
                      content={
                        <div>
                          {t('trustedRoutes.tooltip.notation.recentlyObservedTransitTime')}
                          <br />
                          <S.ReliabilityTooltipNbDay>
                            {t('trustedRoutes.tooltip.notation.nbDays', {
                              reliability_transit_time:
                                trustedRoute.reliability.estimatedTransitTime,
                            })}
                          </S.ReliabilityTooltipNbDay>
                        </div>
                      }
                    >
                      <S.Circle color={colorFromNotation(trustedRoute.reliability.notation)}>
                        {trustedRoute.reliability.notation}
                      </S.Circle>
                    </HoverTooltip>
                  ) : (
                    <EmptyCriteria />
                  )}
                </S.CriterionContent>
                <S.Text>{t('trustedRoutes.criteria.reliability')}</S.Text>
              </S.Criterion>

              <S.Criterion $type='co2'>
                <S.CriterionContent>
                  {trustedRoute.emission ? (
                    <HoverTooltip
                      delayed
                      size='large'
                      content={t('trustedRoutes.tooltip.emission.calculationExplanation')}
                    >
                      <Co2 value={trustedRoute.emission.co2E.toFixed(2)} variant='simple' />
                    </HoverTooltip>
                  ) : (
                    <EmptyCriteria />
                  )}
                </S.CriterionContent>
                <S.Text>{t('trustedRoutes.criteria.co2')}</S.Text>
              </S.Criterion>
              {activeQueryParams.withRates ? (
                <S.Criterion $type='rate'>
                  <S.CriterionContent>
                    {rate ? (
                      <HoverTooltip
                        delayed
                        content={
                          <div>
                            <div>{t('trustedRoutes.rates.all_in')}</div>
                            <br />
                            <div>{`${t('trustedRoutes.rates.rate')}: ${formatRateType(rate)}`}</div>
                            <div>{`${t('trustedRoutes.rates.container_type')}: ${t(
                              `trustedRoutes.rates.${displayRateType}`
                            )}`}</div>
                            <div>{`${t('trustedRoutes.rates.reference')}: ${
                              rate.reference || 'n/a'
                            }`}</div>
                            <div>{`${t('trustedRoutes.rates.commodity')}: ${
                              rate.commodity || 'n/a'
                            }`}</div>
                            <div>{`${t('trustedRoutes.rates.company')}: ${rate.nac || 'n/a'}`}</div>
                          </div>
                        }
                      >
                        <S.BestRate>{value}</S.BestRate>
                      </HoverTooltip>
                    ) : (
                      <EmptyCriteria />
                    )}
                  </S.CriterionContent>
                  <S.Text>{trustedRoute.bestRate20Dry?.currency || 'USD'}</S.Text>
                </S.Criterion>
              ) : null}
            </S.Criteria>
            {isShipper && (
              <Button
                type='button'
                text={t('actions.book')}
                variant='outline'
                onClick={(e: MouseEvent<HTMLElement>) => {
                  e.stopPropagation() // Prevent the accordion from opening/closing
                  convertToBooking(trustedRoute)
                }}
              />
            )}

            {alerts.length > 0 && (
              <S.AlertIconTooltip>
                <IconTooltip
                  content={
                    <>
                      {alerts.map((v, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={`alert${i}`}>
                          {currentLanguage === 'fr' ? v.messageFr : v.messageEn}
                        </div>
                      ))}
                    </>
                  }
                  width={40}
                  height={40}
                  size='nowrap'
                  color='warn'
                  placement='top-left'
                  variant='warning'
                />
              </S.AlertIconTooltip>
            )}
          </S.Card>
        }
      >
        <Tabs>
          <Tab
            id='transport_details'
            label={t('trustedRoutes.transport_details')}
            testId={getTestIdForTrustedRouteTabTransportDetails(index)}
            key={0}
          >
            <div style={{ display: 'flex' }}>
              <TransportDetails trustedRoute={trustedRoute} />
              <TrustedRouteMap trustedRoute={trustedRoute} />
            </div>
          </Tab>
          {activeQueryParams.withRates && trustedRoute.rates && trustedRoute.rates.length > 0 && (
            <Tab
              id='rates'
              label={t('trustedRoutes.rates.title')}
              testId={getTestIdForTrustedRouteTabRates(index)}
              key={1}
            >
              <Rates rates={trustedRoute.rates} />
            </Tab>
          )}
        </Tabs>
      </AccordionItem>
    </S.Container>
  )
}

export default Item
