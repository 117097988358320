import React from 'react'

import { useTheme } from 'styled-components'

import Icon, { IconType } from 'components/icon'

import HoverTooltip from 'components/hover_tooltip'

import { FinalTheme } from 'styles/theme'

import type { TooltipPlacementType, TooltipSizeType } from 'components/tooltip/types'

interface IconTooltipProps {
  color?: 'white' | 'primary' | 'warn'
  content: React.ReactChild
  height?: number
  inline?: boolean
  placement?: TooltipPlacementType
  size?: TooltipSizeType
  variant?: IconType
  width?: number
}

const getIconColor = ($variant: IconType | undefined, theme: FinalTheme) => {
  if ($variant === 'warning') {
    return theme.warn
  }
  return theme.primary
}

const IconTooltip: React.FC<IconTooltipProps> = ({
  placement = 'top',
  size = 'small',
  width = 20,
  height = 20,
  inline = false,
  variant = 'info',
  color,
  content,
}) => {
  const theme = useTheme()
  return (
    <HoverTooltip content={content} size={size} color={color} placement={placement} inline={inline}>
      <Icon name={variant} width={width} height={height} fill={getIconColor(variant, theme)} />
    </HoverTooltip>
  )
}
export default IconTooltip
