import React from 'react'

import DateHelper from 'services/helpers/date_helper'

import {
  StyledItem,
  StyledItemDate,
  StyledItemText,
  StyledItemTitle,
} from 'components/alert_list_item/style'

const AlertListItem = ({
  declaredAt,
  title,
  details,
}: {
  declaredAt: string
  title: string
  details: string | null
}) => (
  <StyledItem>
    <StyledItemDate>{new DateHelper(declaredAt).toLocale()}</StyledItemDate>
    <div>
      <StyledItemTitle>{title}</StyledItemTitle>
      {details && <StyledItemText>{details}</StyledItemText>}
    </div>
  </StyledItem>
)

export default AlertListItem
