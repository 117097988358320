import { css } from 'styled-components'

import { borderRadiusSmall } from 'styles/utils/border_radius'
import { tablet } from 'styles/utils/breakpoints'
import { easeQuartOut } from 'styles/utils/ease'
import { text14Regular } from 'styles/utils/texts'

export default css`
  .react-datepicker {
    border: none;
    padding: 24px 35px;
    display: flex;

    @media ${tablet.down} {
      width: 100vw;
      flex-direction: column;

      .react-datepicker__time-container {
        border-left: 0;
        border-top: 1px solid ${({ theme }) => theme.softGray};
        margin-top: 20px;
      }
      .react-datepicker__time-container,
      .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
        width: 100%;
      }

      .react-datepicker__time-list {
        display: flex;
        width: 100%;
        overflow-x: scroll;
        height: 30px !important;
      }
    }
  }
  .react-datepicker__input-container {
    input {
      displany: none;
    }
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker-popper {
    background: ${({ theme }) => theme.white};
    box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07),
      0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
    padding: 0;
    ${borderRadiusSmall};
  }
  .react-datepicker__close-icon {
    right: 45px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;

    &::before {
      content: '';
      flex: none;
      display: block;
      width: 22px;
      height: 22px;
      font-size: 12px;
      color: white;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${({ theme }) => theme.primary};
      transition: background 0.5s ${easeQuartOut};
    }

    &:hover {
      &:before {
        background-color: ${({ theme }) => theme.mediumGray};
      }
    }

    &::after {
      content: '×';
      font-family: 'Sora';
      flex: none;
      background: none;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 14px;
    }
  }
  .react-datepicker__navigation {
    display: none;
  }
  .react-datepicker__current-month {
    display: none;
  }
  .react-datepicker__week {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  .react-datepicker__header {
    background-color: transparent;
    border-bottom: 0;
    padding: 0;
  }
  .react-datepicker__header__dropdown {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
  }
  .react-datepicker__month-dropdown-container {
    ${borderRadiusSmall}
    background-color:${({ theme }) => theme.clearGray};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
  }
  .react-datepicker__day-names {
    margin: 0 0.4rem;
    display: flex;
    justify-content: center;
  }
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    border: 0;
    ${text14Regular}
    background:transparent;
    cursor: pointer;
  }
  .react-datepicker__month-select {
    color: ${({ theme }) => theme.primary};
    padding-left: 10px;
    padding-right: 5px;
    &:focus-visible {
      outline: none;
    }
  }
  .react-datepicker__year-select {
    color: ${({ theme }) => theme.textDark};
    padding-left: 10px;
    outline: none;
    &:focus-visible {
      outline: none;
    }
    option {
      outline: none;
    }
    &::-ms-expand {
      display: none;
    }
  }
  .react-datepicker__day-name {
    ${text14Regular}
    color: ${({ theme }) => theme.textLight};
    margin: 0;
    height: 40px;
    width: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-datepicker__month {
    margin: 0 0.4rem;
  }
  .react-datepicker__day {
    ${text14Regular}
    color: ${({ theme }) => theme.textDark};
    margin: 0;
    width: 40px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;

    &:hover {
      background-color: transparent;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: ${({ theme }) => theme.primary25};
    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.primary};
      height: 40px;
      width: 40px;
      border-radius: 100%;
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--range-end {
    color: ${({ theme }) => theme.white};
    border-radius: 100%;
    z-index: 9;
    outline: none;
  }
  .react-datepicker__day--selected.react-datepicker__day--in-selecting-range,
  .react-datepicker__day--selected.react-datepicker__day--in-range:not(.react-datepicker__day--range-end) {
    position: relative;
    z-index: 8;
    &:before {
      z-index: -1;
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.primary25};
      height: 34px;
      width: 20px;
      left: 20px;
    }
    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.primary};
      height: 40px;
      width: 40px;
      border-radius: 100%;
    }
  }
  .react-datepicker__day--range-end:not(.react-datepicker__day--range-start) {
    position: relative;
    color: ${({ theme }) => theme.white};
    &:before {
      z-index: -1;
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.primary25};
      height: 34px;
      width: 20px;
      right: 20px;
    }
    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.primary};
      height: 40px;
      width: 40px;
      border-radius: 100%;
    }
    &:hover {
      color: ${({ theme }) => theme.white};
    }
  }
  .react-datepicker__day--in-range:not(.react-datepicker__day--range-end, .react-datepicker__day--selected) {
    &:hover {
      background-color: ${({ theme }) => theme.clearGray};
      color: ${({ theme }) => theme.primary};
    }
  }
  .react-datepicker__day--in-selecting-range {
    &:hover {
      &:after {
        height: 34px !important;
        border-radius: 0 !important;
      }
    }
    &.react-datepicker__day--selected {
      &:hover {
        &:after {
          height: 40px !important;
          border-radius: 100% !important;
        }
      }
    }
  }

  .react-datepicker__day:not(.react-datepicker__day--range-end, .react-datepicker__day--in-range, .react-datepicker__day--selected):hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    position: relative;
    z-index: 8;
    color: ${({ theme }) => theme.primary};
    height: 34px;
    outline: none;
    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.clearGray};
      height: 40px;
      width: 40px;
      border-radius: 100%;
    }
  }
  .react-datepicker__day {
    &--outside-month,
    &--disabled {
      color: ${({ theme }) => theme.textLight};
      opacity: 0.4;
    }
  }
  .react-datepicker__time-container {
    border-left: 1px solid ${({ theme }) => theme.softGray};
  }
  .react-datepicker-time__header {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${text14Regular};
    color: ${({ theme }) => theme.textLight};
  }
  ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${text14Regular};
    color: ${({ theme }) => theme.textDark};

    &:hover {
      color: ${({ theme }) => theme.primary} !important;
    }
    &--selected {
      background-color: ${({ theme }) => theme.primary} !important;
      color: ${({ theme }) => theme.white} !important;

      &:hover {
        color: ${({ theme }) => theme.white} !important;
      }
    }
  }
`
