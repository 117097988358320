import 'utils/profilers/wdyr'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import browsersList from 'utils/supported_browsers'

import App from 'app'

import ErrorLogger from 'services/error/logger'
import store from 'services/store/store'
import history from 'services/store/history'
import { TrackerProvider } from 'services/analytics/tracker_provider'

import 'views/locales/i18n'
import LegacyBrowsers from 'views/errors/legacy_browsers'

const isBrowserSupported = browsersList.test(navigator.userAgent)

ErrorLogger.initialize()
const ErrorBoundary = ErrorLogger.createErrorBoundary()

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      {isBrowserSupported ? (
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <TrackerProvider>
              <App />
            </TrackerProvider>
          </ConnectedRouter>
        </Provider>
      ) : (
        <LegacyBrowsers />
      )}
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)
