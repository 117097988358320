import styled from 'styled-components'

import { textSmallDescription } from 'styles/utils/texts'
import { ReactComponent as ArrowsSwitch } from 'assets/icons/arrows-switch.svg'
import { easeQuartOut } from 'styles/utils/ease'

export const StyledText = styled.p`
  ${textSmallDescription};
`

export const StyledSwitchIcon = styled(ArrowsSwitch)`
  width: 18px;
  height: 16px;
  fill: ${({ theme }) => theme.primary};
`

export const StyledViewSwitcher = styled.button`
  width: 100%;
  padding: 12px 18px 12px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.primary10};
  color: ${({ theme }) => theme.primary};
  transition: background 0.5s ${easeQuartOut};

  &:hover {
    background-color: ${({ theme }) => theme.primary};
    color: white;

    ${StyledSwitchIcon} {
      fill: white;
    }
  }
`
