import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { DatesMilestoneType } from 'types/milestones'

import useModal from 'components/modal/hooks/use_modal'
import Modal from 'components/modal'
import { TDates } from 'components/input_datepicker'
import Button from 'components/button'

import useAppDispatch from 'services/hooks/use_app_dispatch'
import { isEmptyArray } from 'services/helpers/values'
import DateHelper from 'services/helpers/date_helper'

import { StyledModalWrapper } from 'views/shipment/style'
import useDateDeclarationForm from 'views/shipment/hooks/use_date_declaration_form'
import { fetchTimeline, updateMilestoneDates } from 'views/shipment/slice'
import ShipmentContext from 'views/shipment/context'
import { addNotification } from 'views/notifications/slice'
import buildMilestoneParams from 'views/shipment/helpers/milestone'
import MilestoneDatepicker from 'views/shipment/components/milestone_datepicker'

import type { MilestoneSource } from 'constants/shipments'

export interface DateDeclarationModalProps {
  actualTime: string
  actualTimeSource: MilestoneSource
  nextActualTime: string
  estimatedTime: string
  estimatedTimeSource: MilestoneSource
  nextEstimatedTime: string
  type: DatesMilestoneType
  addressId: number
  segmentInfoId: number
}

const DateDeclarationModal: FC<DateDeclarationModalProps> = ({
  actualTime,
  actualTimeSource,
  nextActualTime,
  estimatedTime,
  estimatedTimeSource,
  nextEstimatedTime,
  type,
  addressId,
  segmentInfoId,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { setOpen } = useModal('timelineDateDeclarationModal')

  const { id } = useContext(ShipmentContext)

  const onCloseModal = () => setOpen(false)

  const {
    actualTimeFilter,
    estimatedTimeFilter,
    estimatedTimeEmptiedFilter,
    actualTimeEmptiedFilter,
    estimatedTimeRolledBackFilter,
    actualTimeRolledBackFilter,
    editedQueryParams: datesQueryParams,
    isEdited,
  } = useDateDeclarationForm(estimatedTime, actualTime)

  const { milestoneType } = buildMilestoneParams(type)

  const onApply = () => {
    dispatch(
      updateMilestoneDates({
        // TODO: REFACTOR: https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/#extended-example
        id: id!,
        milestoneType: type,
        addressId,
        segmentInfoId,
        ...datesQueryParams,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('shipments.dateDeclarationModal.updateAlert.title'),
            text: t('shipments.dateDeclarationModal.updateAlert.successMessage'),
          })
        )
        dispatch(fetchTimeline({ id: id! }))
          .unwrap()
          .catch(() => {
            dispatch(
              addNotification({
                type: 'alert',
                title: t('errors.notification.title'),
                text: t('errors.notification.content'),
              })
            )
          })
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('shipments.dateDeclarationModal.updateAlert.title'),
            text: t('shipments.dateDeclarationModal.updateAlert.errorMessage'),
          })
        )
      })
  }

  return (
    <Modal position='center' onClose={onCloseModal} modalName='timelineDateDeclarationModal'>
      <Modal.Header>
        {t(`shipments.dateDeclarationModal.${milestoneType}.title`, {
          name: t(`static.milestones.${type}`),
        })}
      </Modal.Header>
      <Modal.Content>
        <StyledModalWrapper>
          <MilestoneDatepicker
            label={t(`shipments.dateDeclarationModal.${milestoneType}.estimatedLabel`)}
            name={estimatedTimeFilter.name}
            onChange={(changes) => {
              estimatedTimeFilter.onChange(changes)
              estimatedTimeEmptiedFilter.setValue(isEmptyArray(changes.value))
              estimatedTimeRolledBackFilter.setValue(false)
            }}
            startDate={estimatedTimeFilter.value}
            milestoneSource={estimatedTimeSource}
            nextMilestoneDate={nextEstimatedTime}
            onAdminDateRolledBack={() => {
              estimatedTimeFilter.setValue(nextEstimatedTime)
              estimatedTimeEmptiedFilter.setValue(false)
              estimatedTimeRolledBackFilter.setValue(true)
            }}
            dateRolledBack={estimatedTimeRolledBackFilter.value}
            withPortal
            showTimeSelect
          />
          <MilestoneDatepicker
            label={t(`shipments.dateDeclarationModal.${milestoneType}.actualLabel`)}
            name={actualTimeFilter.name}
            onChange={(changes) => {
              actualTimeFilter.onChange(changes)
              actualTimeEmptiedFilter.setValue(isEmptyArray(changes.value))
              actualTimeRolledBackFilter.setValue(false)
            }}
            startDate={actualTimeFilter.value}
            milestoneSource={actualTimeSource}
            nextMilestoneDate={nextActualTime}
            onAdminDateRolledBack={() => {
              actualTimeFilter.setValue(nextActualTime)
              actualTimeEmptiedFilter.setValue(false)
              actualTimeRolledBackFilter.setValue(true)
            }}
            dateRolledBack={actualTimeRolledBackFilter.value}
            withPortal
            filterDate={(date: TDates) =>
              new DateHelper(date).isBeforeOrEqual(new DateHelper(new Date()))
            }
            showTimeSelect
          />
        </StyledModalWrapper>
      </Modal.Content>
      <Modal.Footer>
        <Button text={t('actions.cancel')} onClick={onCloseModal} rounded variant='clear' />
        <Button
          disabled={!isEdited}
          text={t('actions.apply')}
          onClick={onApply}
          rounded
          variant='highlight'
        />
      </Modal.Footer>
    </Modal>
  )
}

export default DateDeclarationModal
