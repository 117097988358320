import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from 'services/store/store'

const ssoSlice = createSlice({
  name: 'sso',
  initialState: {
    loginUrl: '',
    logoutRedirectUrl: '',
    logoutHint: '',
    isSSOUser: false,
    customDomain: '',
    clientId: '',
    logoutInitiatedByUser: false,
  },
  reducers: {
    setSsoUrls: (state, action) => {
      state.loginUrl = action.payload.loginUrl
      state.logoutRedirectUrl = action.payload.logoutRedirectUrl
    },
    setLogoutHint: (state, action) => {
      state.logoutHint = action.payload
    },
    setIsSSOUser: (state, action) => {
      state.isSSOUser = action.payload
    },
    setSSOClientId: (state, action) => {
      state.clientId = action.payload
    },
    setCustomDomain: (state, action) => {
      state.customDomain = action.payload
    },
    setLogoutInitiatedByUser: (state, action) => {
      state.logoutInitiatedByUser = action.payload
    },
  },
})

export const isSSOUser = (state: RootState) => state.sso.isSSOUser

export const getLogoutRedirectUrl = (state: RootState) => state.sso.logoutRedirectUrl
export const getLoginUrl = (state: RootState) => state.sso.loginUrl
export const getLogoutHint = (state: RootState) => state.sso.logoutHint
export const getClientId = (state: RootState) => state.sso.clientId
export const getCustomDomain = (state: RootState) => state.sso.customDomain
export const getLogoutInitiatedByUser = (state: RootState) => state.sso.logoutInitiatedByUser

export const {
  setSsoUrls,
  setIsSSOUser,
  setLogoutHint,
  setSSOClientId,
  setCustomDomain,
  setLogoutInitiatedByUser,
} = ssoSlice.actions

export default ssoSlice.reducer
