import { FieldNamesMarkedBoolean, UseFormGetValues, UseFormSetValue } from 'react-hook-form'

import { t } from 'i18next'

import { TransportType } from 'types/shipments'

import {
  ADMIN_SOURCES,
  MilestoneSource,
  TRANSPORT_TYPE_AIR,
  TRANSPORT_TYPE_PARCEL,
  TRANSPORT_TYPE_SEA,
} from 'constants/shipments'
import { isPresent } from 'services/helpers/values'

import {
  TimelineFormAlert,
  TimelineFormMilestone,
  TimelineFormProps,
  TimelineFormSegment,
  TimelineFormStep,
} from 'views/shipment/types/segment_timelines'

import {
  StepType,
  StopoverType,
  STEP_TYPE_AIR_STOPOVER,
  STEP_TYPE_PARCEL_STOPOVER,
  STEP_TYPE_SEA_TRANSSHIPMENT,
} from 'constants/steps'

import DateHelper from 'services/helpers/date_helper'
import { EMPTY_VALUE } from 'constants/milestones'
import { EditTimelinePayload } from 'views/shipment/slice'

export const STOPOVER_STEP_BY_TRANSPORT_TYPE: { [k in TransportType]?: StopoverType } = {
  [TRANSPORT_TYPE_SEA]: STEP_TYPE_SEA_TRANSSHIPMENT,
  [TRANSPORT_TYPE_AIR]: STEP_TYPE_AIR_STOPOVER,
  [TRANSPORT_TYPE_PARCEL]: STEP_TYPE_PARCEL_STOPOVER,
}

export const getAlertTranslationKey = (transportType: TransportType) => {
  if (transportType === TRANSPORT_TYPE_SEA)
    return 'shipments.editTimelineModal.alerts.transshipmentsAlert'
  if (transportType === TRANSPORT_TYPE_PARCEL)
    return 'shipments.editTimelineModal.alerts.sortingCentersAlert'

  return 'shipments.editTimelineModal.alerts.stopoversAlert'
}

export const stepHasBeenEditedByAdmin = (
  step: TimelineFormStep,
  milestones: TimelineFormMilestone[]
) =>
  (step.sourceMetadata?.location && ADMIN_SOURCES.includes(step.sourceMetadata.location)) ||
  milestones.some((milestone) => milestoneHasBeenEditedByAdmin(milestone))

export const milestoneHasBeenEditedByAdmin = (milestone: TimelineFormMilestone) =>
  (isPresent(milestone.sourceMetadata) &&
    [
      milestone.sourceMetadata.actualTime,
      milestone.sourceMetadata.estimatedTime,
      milestone.sourceMetadata.plannedTime,
    ].some((source) => isPresent(source) && ADMIN_SOURCES.includes(source))) ||
  !milestone.initialState.active

export const addNewAlert = ({
  alert,
  getValues,
  setValue,
}: {
  alert: TimelineFormAlert
  getValues: UseFormGetValues<TimelineFormProps>
  setValue: UseFormSetValue<TimelineFormProps>
}) => {
  const alerts = getValues('alerts')

  if (alerts.some((a) => a.name === alert.name)) return

  setValue('alerts', [...alerts, alert])
}

export const getNextStopoverIndex = ({
  steps,
  stopoverType,
  polType,
}: {
  steps: TimelineFormStep[]
  stopoverType: StepType
  polType: StepType
}) => {
  let nextStopoverIndex = -1

  const lastStopOverIndex = steps
    .reverse()
    .findIndex((step: TimelineFormStep) => step.type === stopoverType)

  if (lastStopOverIndex === -1) {
    nextStopoverIndex = steps.findIndex((step: TimelineFormStep) => step.type === polType)
  } else {
    nextStopoverIndex = steps.length - lastStopOverIndex
  }

  return nextStopoverIndex
}

export const formatMilestoneDate = (milestone: TimelineFormMilestone) => {
  if (milestoneHasBeenReverted(milestone)) {
    const dateInfo = getNextMilestoneDateInformationAfterReversion(milestone)

    return `${dateInfo.label}: ${
      isPresent(dateInfo.value) ? new DateHelper(dateInfo.value).toLocale({ hours: true }) : 'N/A'
    }`
  }

  if (isPresent(milestone.actualTime) && milestone.actualTime !== EMPTY_VALUE)
    return `${t('shipments.milestones.actualTime')}: ${new DateHelper(
      milestone.actualTime
    ).toLocale({ hours: true })}`

  if (isPresent(milestone.estimatedTime) && milestone.estimatedTime !== EMPTY_VALUE)
    return `${t('shipments.milestones.expectedTime')}: ${new DateHelper(
      milestone.estimatedTime
    ).toLocale({ hours: true })}`

  return `${t('shipments.milestones.plannedTime')}: ${
    isPresent(milestone.plannedTime) && milestone.plannedTime !== EMPTY_VALUE
      ? new DateHelper(milestone.plannedTime).toLocale({ hours: true })
      : 'N/A'
  }`
}

const mergeDuplicateSteps = (steps: TimelineFormStep[]) => {
  const result: TimelineFormStep[] = []

  steps.forEach((step) => {
    if (result.find((s) => s.location?.id === step.location?.id)) return

    const matchingSteps = steps.filter((s) => s.location?.id === step.location?.id)

    if (matchingSteps.length < 2) {
      result.push(step)
      return
    }

    const activeStep = matchingSteps.find((s) => s.active)
    result.push(activeStep ?? matchingSteps[0])
  })

  return result
}

const filterMilestonesToRemove = (
  milestones: TimelineFormMilestone[],
  milestonesToRemove: TimelineFormMilestone[]
): TimelineFormMilestone[] =>
  milestones.filter(
    (milestone) =>
      !milestonesToRemove.some(
        (milestoneToRemove) =>
          milestoneToRemove.location.id === milestone.location.id &&
          milestoneToRemove.type === milestone.type
      )
  )

export const generatePayload = (
  segments: TimelineFormSegment[],
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<TimelineFormProps>>>
): EditTimelinePayload => ({
  segments:
    dirtyFields.segments
      ?.map((dirtySegment, segmentIndex) => {
        const segment = segments[segmentIndex]
        const milestones = dirtySegment.milestones?.map((dirtyMilestone, milestoneIndex) => {
          const milestone = segment.milestones[milestoneIndex]
          const milestoneData: TimelineFormMilestone = {
            ...milestone,
            plannedTime: undefined,
            estimatedTime: undefined,
            actualTime: undefined,
          }

          /* 
           #HACKEO, when the metadata are null, the form initialize the dirtyness
           of the fields as a simple boolean instead of a plain object with one key by field
           it's simpler to check the dirtyness here than to mess with defaultValues, 
           we do not want to send an empty object as metadata as it breaks the view 
          */
          const metadataHasBeenTouched =
            typeof dirtyMilestone?.metadata === 'boolean'
              ? dirtyMilestone?.metadata
              : Object.values(dirtyMilestone?.metadata || {}).some(Boolean)

          if (metadataHasBeenTouched) {
            milestoneData.metadata = milestone.metadataHasBeenRolledBack ? null : milestone.metadata
          }

          if (dirtyMilestone.plannedTime)
            milestoneData.plannedTime = milestone.plannedTimeHasBeenRolledBack
              ? null
              : milestone.plannedTime ?? EMPTY_VALUE
          if (dirtyMilestone.estimatedTime)
            milestoneData.estimatedTime = milestone.estimatedTimeHasBeenRolledBack
              ? null
              : milestone.estimatedTime ?? EMPTY_VALUE
          if (dirtyMilestone.actualTime)
            milestoneData.actualTime = milestone.actualTimeHasBeenRolledBack
              ? null
              : milestone.actualTime ?? EMPTY_VALUE

          const isMilestoneDirty =
            dirtyMilestone.plannedTime ||
            dirtyMilestone.estimatedTime ||
            dirtyMilestone.actualTime ||
            dirtyMilestone.active ||
            metadataHasBeenTouched

          return { milestone: milestoneData, isMilestoneDirty }
        })

        const preStopoversSteps = dirtySegment.preStopoversSteps?.map((dirtyStep, stepIndex) => {
          const step = segment.preStopoversSteps[stepIndex]
          const isStepDirty = dirtyStep.location?.id

          return { step, isStepDirty }
        })

        const postStopoversSteps = dirtySegment.postStopoversSteps?.map((dirtyStep, stepIndex) => {
          const step = segment.postStopoversSteps[stepIndex]
          const isStepDirty = dirtyStep.location?.id

          return { step, isStepDirty }
        })

        let dirtySteps = preStopoversSteps?.filter((s) => s.isStepDirty).map((s) => s.step) ?? []

        // If any stopover is touched, we must send the complete list of stopovers
        if (segment.stopovers.touched) {
          dirtySteps = dirtySteps.concat(segment.stopovers.values)
        }

        dirtySteps = dirtySteps.concat(
          postStopoversSteps?.filter((s) => s.isStepDirty).map((s) => s.step) ?? []
        )

        const dirtyMilestones =
          milestones?.filter((m) => m.isMilestoneDirty).map((m) => m.milestone) ?? []

        const milestonesToRemove = segment.milestones.filter(
          (m) => !m.initialState.active && m.activeHasBeenRolledBack
        )

        const isSegmentDirty =
          dirtySteps.length > 0 ||
          dirtyMilestones.length > 0 ||
          segment.stepsToRemove.length > 0 ||
          milestonesToRemove.length > 0

        const uniqSteps = mergeDuplicateSteps(dirtySteps.filter((s) => isPresent(s.location?.id)))

        return {
          segment: {
            id: segment.id,
            steps: uniqSteps,
            milestones: filterMilestonesToRemove(dirtyMilestones, milestonesToRemove),
            stepsToRemove: segment.stepsToRemove,
            milestonesToRemove,
          },
          isSegmentDirty,
        }
      })
      .filter((s) => s.isSegmentDirty)
      .map((s) => s.segment) ?? [],
})

const isNotFromAdminSource = (source: MilestoneSource | undefined) =>
  isPresent(source) && !ADMIN_SOURCES.includes(source)

export const getNextMilestoneDateInformationAfterReversion = (
  milestone: TimelineFormMilestone
): {
  label: string
  value?: string | null
} => {
  const timesByPriority = ['actualTime', 'estimatedTime', 'plannedTime'] as const

  const currentTimeNotFromAdminSource = timesByPriority.find((time) =>
    isNotFromAdminSource(milestone.sourceMetadata[time])
  )

  if (isPresent(currentTimeNotFromAdminSource)) {
    return {
      label: t(`shipments.milestones.${currentTimeNotFromAdminSource}`),
      value: milestone[currentTimeNotFromAdminSource],
    }
  }

  const timeFromNextSource = timesByPriority.find((time) => isPresent(milestone.nextDates[time]))

  if (!isPresent(timeFromNextSource)) {
    return {
      label: t('shipments.milestones.plannedTime'),
      value: null,
    }
  }

  return {
    label: t(`shipments.milestones.${timeFromNextSource}`),
    value: milestone.nextDates[timeFromNextSource],
  }
}

export const milestoneHasBeenReverted = (milestone: TimelineFormMilestone) =>
  !milestone.initialState.active && milestone.activeHasBeenRolledBack
