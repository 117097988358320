import { createAsyncThunk } from '@reduxjs/toolkit'

import { UserData, UserSettings } from 'features/users/services/types'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'
import useFormData from 'services/api/hooks/use_form_data'

import useUrlParams from 'services/api/hooks/use_url_params'

import type { RootState } from 'services/store/store'

export const createUser = createAsyncThunk(
  'users/createUser',
  async (userData: { user: UserData }, thunkAPI) =>
    InternalClient.post('/users.json', userData)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const importUsers = createAsyncThunk(
  'users/importUsers',
  async ({ file }: { file: File }, thunkAPI) => {
    const formData = useFormData({
      file,
    })
    return InternalClient.post('/users/import_users', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const downloadImportUsersTemplate = createAsyncThunk(
  'users/downloadImportUsersTemplate',
  async (_, thunkAPI) =>
    InternalClient.get('/users/template_import_users', { responseType: 'blob' })
      .then((r) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', 'users_import_template.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch(onError(thunkAPI))
)

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (userData: { user: UserData }, thunkAPI) =>
    InternalClient.patch('/users/admin_update.json', userData)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const fetchUserSettings = createAsyncThunk<
  UserSettings,
  { id: number },
  { state: RootState }
>('users/fetchUserSettings', async ({ id }, thunkAPI) =>
  InternalClient.get(`users/settings/${id}`)
    .then((r) => r.data)
    .catch(onError(thunkAPI))
)

export const toggleNotifications = createAsyncThunk(
  'users/toggleNotifications',
  async ({ id }: { id: number }, thunkAPI) =>
    InternalClient.post(`/users/toggle_notifications/${id}`)
      .then((r) => r.data)
      .then((user) => ({ id: user.id, changes: user }))
      .catch(onError(thunkAPI))
)

export const toggleActivation = createAsyncThunk(
  'users/toggleActivation',
  async ({ id }: { id: number }, thunkAPI) =>
    InternalClient.get(`/users/toggle_activate_user/${id}`)
      .then((r) => r.data)
      .then((user) => ({ id: user.id, changes: user }))
      .catch(onError(thunkAPI))
)

export const sendNewCredentials = createAsyncThunk(
  'users/sendNewCredentials',
  async ({ id }: { id: number }, thunkApi) =>
    InternalClient.put(`/users/send_new_creds/${id}`)
      .then((r) => r.data)
      .then((user) => ({ id: user.id, changes: user }))
      .catch(onError(thunkApi))
)

export const sendSsoWelcomeEmail = createAsyncThunk(
  'users/sendSsoWelcomeEmail',
  async ({ id }: { id: number }, thunkApi) =>
    InternalClient.put(`/users/send_sso_welcome_mail/${id}`)
      .then((r) => r.data)
      .then((user) => ({ id: user.id, changes: user }))
      .catch(onError(thunkApi))
)

export const DEFAULT_USERS_PER_PAGE = 30

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (
    {
      page = 1,
      per = DEFAULT_USERS_PER_PAGE,
      organizationId,
      filtered,
    }: { page: number; per?: number; filtered?: string; organizationId?: number },
    thunkAPI
  ) => {
    // Filters are named "filtered" and "forOrganization" because of an implementation detail on our backend.
    // The name of the filter is the name of the model scope we use.
    const url = useUrlParams('/users', { page, per, filtered, forOrganization: organizationId })

    return InternalClient.get(url)
      .then((r) => r.data)
      .then(({ pagination: { totalCount }, users }) => ({ totalCount, users }))
      .catch(onError(thunkAPI))
  }
)
