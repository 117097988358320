export const USER = 'User' as const
export const SHIPPER = 'Shipper' as const
export const FORWARDER = 'Forwarder' as const
export const ADMIN = 'Admin' as const
export const SUPER_ADMIN = 'SuperAdmin' as const
export const ADMIN_READ = 'AdminRead' as const
export const OUTSOURCING = 'Outsourcing' as const
export const EXTERNAL_SERVICE = 'ExternalService' as const
export const ANALYTICS = 'Analytics' as const
export const LOGISTICIAN = 'Logistician' as const
export const EDIT = 'Edit' as const
export const USER_ADMIN = 'UserAdmin' as const
export const KEY_USER = 'KeyUser' as const
export const TRUSTED_ROUTES_USER = 'TrustedRoutesUser' as const

export const OPTIONAL_ROLES = [
  ANALYTICS,
  LOGISTICIAN,
  EDIT,
  KEY_USER,
  USER_ADMIN,
  SHIPPER,
  FORWARDER,
  TRUSTED_ROUTES_USER,
]

export const MAIN_ROLES = [ADMIN, OUTSOURCING, SUPER_ADMIN, EXTERNAL_SERVICE, USER, ADMIN_READ]

export const ALL_ROLES = [...OPTIONAL_ROLES, ...MAIN_ROLES] as const
