import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import InputDatepicker, { InputDatepickerProps } from 'components/input_datepicker'
import { StyledDatePickerContainer } from 'components/input_datepicker/style'
import { ADMIN_SOURCES, MilestoneSource } from 'constants/shipments'
import IconTooltip from 'components/icon_tooltip'
import DateHelper from 'services/helpers/date_helper'
import Button from 'components/button'
import { StyledButtonIconTooltip } from 'components/button/style'
import { isPresent } from 'services/helpers/values'
import { StyledMilestoneDatepickerLabel } from 'views/shipment/style'
import useUserCan from 'views/iam/hooks/use_user_can'
import { EVENT_UPDATE } from 'constants/permissions'

interface MilestoneDatepickerProps extends InputDatepickerProps {
  milestoneSource?: MilestoneSource
  nextMilestoneDate?: string
  onAdminDateRolledBack?: () => void
  dateRolledBack?: boolean | null
}

const MilestoneDatepicker: FC<MilestoneDatepickerProps> = ({
  name,
  label,
  milestoneSource,
  nextMilestoneDate,
  onAdminDateRolledBack,
  dateRolledBack,
  ...props
}) => {
  const { t } = useTranslation()
  const userCan = useUserCan()

  const shouldDisplayAdminLabel =
    userCan(EVENT_UPDATE) &&
    dateRolledBack === false &&
    isPresent(milestoneSource) &&
    ADMIN_SOURCES.includes(milestoneSource)
  const tooltipDate = (date: string) => {
    const dateHelper = new DateHelper(date)
    return dateHelper.toLocale({ hours: dateHelper.hasHours() })
  }

  const tooltipText = isPresent(nextMilestoneDate)
    ? t('shipments.dateDeclarationModal.nextAvailableDate.one', {
        date: tooltipDate(nextMilestoneDate),
      })
    : t('shipments.dateDeclarationModal.nextAvailableDate.none')

  return (
    <StyledDatePickerContainer>
      <StyledMilestoneDatepickerLabel htmlFor={name}>
        {label}
        {shouldDisplayAdminLabel && (
          <>
            <span>- {t('shipments.dateDeclarationModal.adminDate')}</span>
            <StyledButtonIconTooltip
              as={Button}
              iconType={IconTooltip}
              icon='arrow_go_back'
              iconText={tooltipText}
              iconSize='nowrap'
              tooltipColor='white'
              onClick={onAdminDateRolledBack}
            />
          </>
        )}
      </StyledMilestoneDatepickerLabel>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <InputDatepicker name={name} {...props} />
    </StyledDatePickerContainer>
  )
}

export default MilestoneDatepicker
