import styled from 'styled-components'

import { StyledTabNavigation } from 'components/tabs/tab_navigation/style'

export const StyledModalSettingsForm = styled.div`
  ${StyledTabNavigation} {
    margin-bottom: 25px;
  }
`
export const StyledP = styled.p`
  margin-bottom: 20px;
`
